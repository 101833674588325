import Vue from 'vue';
import App from './App.vue';
import axios from '@/axios';
import router from '@/router';
import VueAxios from 'vue-axios';
import { Lazyload } from 'vant';

import 'amfe-flexible/index.js';
import '@/utils';
import '@/axios/api';

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

//开启图片懒加载
Vue.use(Lazyload);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
