







import { Vue, Component } from 'vue-property-decorator';
import wx from 'weixin-js-sdk';

@Component
export default class WeChat extends Vue {
    //分享计时
    private addShareNum(): void {
        this.axios.post(this.$api.ADD_SHARE_NUM);
    }

    //分享
    private share(share: any, success: Function): void {
        //分享朋友圈
        wx.onMenuShareTimeline({
            ...share.pub,
            success
        });

        //分享好友
        wx.onMenuShareAppMessage({
            ...share.pri,
            success
        });

        //分享到qq
        wx.onMenuShareQZone({
            ...share.pri,
            success
        });
    }

    //配置微信
    private config(): void {
        const locationHref = window.location.href.split("#")[0];
        this.axios.post(this.$api.SHARE + locationHref).then((res: any) => {
            if (res.code == 1) {
                const { config, share } = res.data;
                //配置
                wx.config(config);
                //配置完成
                wx.ready(() => {
                    this.$emit('ready', wx);
                    //分享
                    this.share(share, () => {
                        //分享成功
                        this.addShareNum();
                    });
                });
            }
        })
    }

    public created(): void {
        if (this.$token.isToken()) {
            this.config();
        }
    }
}
