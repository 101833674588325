












































import { Component, Vue, Watch } from 'vue-property-decorator';
import GameRule from '@/components/GameRule.vue';
import SeekResult from '@/components/SeekResult.vue';
import Alert from '@/components/Alert.vue';
import Award from '@/components/Award.vue';
import RankList from '@/components/RankList.vue';
import Share from '@/components/Share.vue';

@Component({
    components: {
        'game-rule': GameRule,
        'seek-result': SeekResult,
        'alert': Alert,
        'award': Award,
        'rank-list': RankList,
        'share': Share
    }
})
export default class Index extends Vue {
    //显示游戏规则
    public showGameRule: boolean = false;
    //显示查找答案
    public showSeekResult: boolean = false;
    //显示已结束
    public showEnded: boolean = false;
    //显示未开始
    public showNotStarted: boolean = false;
    //显示奖励
    public showAward: boolean = false;
    //显示排行榜
    public showRankList: boolean = false;
    //显示分享
    public showShare: boolean = false;

    //绑定查看答案
    @Watch('showEnded', { immediate: true })
    public onShowEndedChange(val: boolean): void {
        this.showSeekResult = val;
    }
    @Watch('showNotStarted', { immediate: true })
    public onShowNotStartedChange(val: boolean): void {
        this.showSeekResult = val;
    }

    //跳转答题
    public toAnswer(): void {
        this.$router.replace('/answer');
    }

    //开始游戏
    public play(): void {
        const status = this.$game_data.game_status;
        if (status == 0) {
            this.toAnswer();
        } else if (status == 1) {
            //游戏未开始
            this.showNotStarted = true;
        } else if (status == 2) {
            //游戏结束
            this.showEnded = true;
        } else if (status == 3) {
            //已经完成
            this.showAward = true;
        }
    }
}
