import Vue from 'vue';
import Secret from './Secret';
import Token from './Token';
import WeChatLogin from './WeChatLogin';

//签名钥匙
const signKey: string = 'YdFwDEHE8Xe9uxj7';
//rsa公钥
const rsaPublicKey: string = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCm5ZAbtpg7Emmvc5BhaXDobviqc2lr3vxukl/iV8mFXG+PsXN/su5pDPPQLkhJ1yHByVTOAwPPViLr6qriS9zP2FK3PtONmnTxWNlKNXNOkvf89RNr+Da1OVJ1I5nM8evOk3R/Lb8YUSYC+Ii5H66URLPwVfV+3Cwp8aEHHgLswQIDAQAB';
//rsa私钥
//const rsaPrivateKey: string = '';
//数据加密
const secret = new Secret(signKey, rsaPublicKey);

//令牌
const token = new Token;

const ua = window.navigator.userAgent;
/*平台*/
const platform = {
    Wechat: /MicroMessenger/i.test(ua)
};

//微信登录
const weChatLogin = new WeChatLogin();

export {
    secret,
    platform,
    token,
    weChatLogin
};

//平台环境
Vue.prototype.$platform = platform;
//令牌
Vue.prototype.$token = token;
//游戏数据
Vue.prototype.$game_data = null;

//声明全局
declare module "vue/types/vue" {
    interface Vue {
        $platform: {
            Wechat: boolean
        },
        $token: Token,
        $game_data: any
    }
}