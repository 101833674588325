import { render, staticRenderFns } from "./Answer.vue?vue&type=template&id=61fe2b26&scoped=true&"
import script from "./Answer.vue?vue&type=script&lang=ts&"
export * from "./Answer.vue?vue&type=script&lang=ts&"
import style0 from "./Answer.vue?vue&type=style&index=0&id=61fe2b26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61fe2b26",
  null
  
)

export default component.exports