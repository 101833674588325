import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { token, weChatLogin } from '@/utils';

import Index from '@/pages/Index.vue';
import Answer from '@/pages/Answer.vue';

Vue.use(VueRouter);

const routes = [
    //首页
    { path: '/', meta: { backgroundColor: '#ffd680' }, component: Index,
        beforeEnter: (to: Route, from: Route, next: Function) => {
            weChatLogin.guidance(weChatLogin.pid, weChatLogin.channelID).then((data: any) => {
                Vue.prototype.$game_data = data;
                next();
            }).catch(() => {
                //需要授权
                const code = weChatLogin.getUrlQuery().code;
                if (code) {
                    weChatLogin.oauthCallback(code);
                } else {
                    weChatLogin.login();
                }
            });
        }
    },
    //答题
    { path: '/answer', meta: { backgroundColor: '#ffd87e' }, component: Answer,
        beforeEnter: (to: Route, from: Route, next: Function) => {
            if (token.isToken() && Vue.prototype.$game_data) {
                next();
            } else {
                next('/');
            }
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    //设置页面背景色
    document.body.style.backgroundColor = to.meta!.backgroundColor || '';
    //document.body.style.background
    next();
});

export default router;