












import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class BackgroundMusic extends Vue {
    //音频资源
    @Prop(String)
    public src!: string;

    //播放
    public play(): void {
        this.$nextTick(() => {
            const audio = this.$refs.audio as any;
            if (audio.paused) {
                audio.play();
            }
        });
    }

    public mounted(): void {
        if (this.$token.isToken()) {
            this.$nextTick(() => {
                const audio = this.$refs.audio as any;
                document.addEventListener(this.$platform.Wechat ? 'WeixinJSBridgeReady' : 'DOMContentLoaded', () => {
                    audio.play();
                }, false);

                const play = () => {
                    audio.play();
                    window.removeEventListener("touchend", play);
                };
                window.addEventListener('touchend', play);
            });
        }
    }
}
