

















import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Popup } from 'vant';
import Dialog from '@/mixins/Dialog';

@Component({
    components: {
        'van-popup': Popup
    }
})
export default class Alert extends Mixins(Dialog) {
    //标题
    @Prop(String)
    public title!: string;

    //内容
    @Prop(String)
    public content!: string;

    //人数
    @Prop(Number)
    public succeedNum!: number;

    //开始游戏
    public play(): void {
        this.$emit('play');
        this.close();
    }

}
