/*
    微信登录
*/
import axios from "axios";
import { API } from '@/axios/api';
import { token } from '@/utils';

export default class WeChatLogin {
    private readonly CALLBACK_URL: string = '';
    
    //获取url中的查询方法
    public getUrlQuery(): any {
        const url = location.search;
        const req: any = new Object;
        if (url.indexOf("?") != -1) {
            const strs = url.slice(1).split("&");
            for (let i = 0; i < strs.length; i++) {
                const [ key, value ] = strs[i].split("=");
                req[key] = value;
            }
        }
        return req;
    }

    //引导
    public guidance(pid: string = '', channel: string = ''): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(API.GUIDANCE, { pid, channel }).then((res: any) => {
                if (res.code == 1) {
                    resolve(res.data);
                } else if (res.code == -3) {
                    //需要授权
                    reject();
                }
            });
        });
    }

    //登录
    public login(): void {
        axios.post(API.LOGIN, { callback: this.CALLBACK_URL }).then((res: any) => {
            if (res.code == 1) {
                window.location.replace(res.data.url);
            }
        });
    }

    //认证回调
    public oauthCallback(code: string): void {
        axios.get(API.OAUTH_CALLBACK, { params: { code } }).then((res: any) => {
            if (res.code == 1) {
                token.setToken(res.data.token);
                window.location.replace(this.CALLBACK_URL);
            }
        });
    }

    //邀请父ID
    public get pid(): string | undefined {
        let pid = this.getUrlQuery().pid;
        if (pid) {
            window.localStorage.setItem('pid', pid);
        } else {
            pid = window.localStorage.getItem('pid') || undefined;
        }
        return pid;
    }

    //channelID
    public get channelID(): string | undefined {
        let cid = this.getUrlQuery().channel;
        if (cid) {
            window.localStorage.setItem('cid', cid);
        } else {
            cid = window.localStorage.getItem('cid') || undefined;
        }
        return cid;
    }

    constructor() {
        this.CALLBACK_URL = `${location.protocol}//${location.hostname}${ location.port && (':' + location.port) }`;
    }
}