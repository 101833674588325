















































import { Vue, Component } from 'vue-property-decorator';
import SeekResult from '@/components/SeekResult.vue';
import GameRule from '@/components/GameRule.vue';
import Fail from '@/components/Fail.vue';
import Award from '@/components/Award.vue';
import RankList from '@/components/RankList.vue';
import Share from '@/components/Share.vue';

@Component({
    components: {
        'game-rule': GameRule,
        'seek-result': SeekResult,
        'fail': Fail,
        'award': Award,
        'rank-list': RankList,
        'share': Share
    }
})
export default class Answer extends Vue {
    //显示游戏规则
    public showGameRule: boolean = false;
    //显示失败
    public showFail: boolean = false;
    //显示奖励
    public showAward: boolean = false;
    //显示排行榜
    public showRankList: boolean = false;
    //显示分享
    public showShare: boolean = false;

    //题目数量
    public  topicNum: number = 0;

    //题目列表
    public topicList: any[] = [];

    //统计结果
    public statResult: boolean[] = [];

    //当前题目索引
    public currentIndex: number = 0;
    //当前题目
    public get currentTopic(): any {
        return this.topicList[this.currentIndex];
    }
    //当前题目页码
    public get current(): number {
        return this.currentIndex + 1;
    }
    //当前题目类型0:单选；1：多选
    public get currentTopicType(): 0 | 1 {
        return this.currentTopic.answer.length > 1 ? 1 : 0;
    }

    //是否答题结束
    public get isEnd(): boolean {
        return this.statResult.length >= this.topicNum;
    }

    //选中结果
    public checkedOptions: string[] = [];
    //是否选中项
    public isChecked(item: string): boolean {
        const c = item.charAt(0);
        for (let o of this.checkedOptions) {
            if (o == c) {
                return true;
            }
        }
        return false;
    }
    //选中项
    public checked(item: string): void {
        //答题结束禁止选中
        if (this.isEnd) {
            return undefined;
        }

        const c = item.charAt(0);
        if (this.currentTopicType == 0) {
            //单选
            this.checkedOptions = [c];
        } else if (this.currentTopicType == 1) {
            //多选
            const len = this.checkedOptions.length;
            for (let i = 0; i < len; ++i) {
                const o = this.checkedOptions[i];
                if (o == c) {
                    this.checkedOptions.splice(i, 1);
                    return undefined;
                }
            }
            this.checkedOptions.push(item.charAt(0));
        }
    }

    //检验当前答案
    public checkCurrentResult(): boolean {
        let answer = this.currentTopic.answer;

        const checkAnswer = (c: string): boolean => {
            for (const a of answer) {
                if (a == c) {
                    return true;
                }
            }
            return false;
        };

        if (this.checkedOptions.length == answer.length) {
            for (const c of this.checkedOptions) {
                if (!checkAnswer(c)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    //验证结果
    public checkResult(): boolean {
        for (const b of this.statResult) {
            if (!b) {
                return false;
            }
        }
        return true;
    }

    //随机题目
    private randomTopic(list: any[]): any {
        const index = Math.floor(Math.random() * list.length);
        return list.splice(index, 1)[0];
    }
    private batchRandomTopic(list: any[], num: number): any[] {
        let topics = [];
        if (list.length >= num) {
            for (let i = 0; i < num; ++i) {
                topics.push(this.randomTopic(list));
            }
        }
        return topics;
    }

    //获取题目列表
    public fetchTopicList(callback?: Function): void {
        this.axios.get(this.$api.TOPIC_LIST).then((data: any) => {
            this.topicList = [];
            for (let list of data.lists) {
                this.topicList = this.topicList.concat(this.batchRandomTopic(list, 1));
            }
            this.topicNum = this.topicList.length;
            callback && callback();
        });
    }

    //答题结束
    private end(): void {
        if (this.checkResult()) {
            //通过
            if (this.$game_data.game_status == 0) {
                this.showAward = true;
            } else {
                this.reset();
            }
        } else {
            //未通过
            this.showFail = true;
        }
    }

    //下一题
    public next(): void {
        //没有选选项
        if (this.checkedOptions.length <= 0 || this.isEnd) {
            return undefined
        }

        //统计结果
        this.statResult.push(this.checkCurrentResult());

        //答题结束
        if (this.isEnd) {
            this.end();
            return undefined;
        }

        //切换题目
        ++this.currentIndex;
        this.checkedOptions = [];
    }

    //重新开始
    public reset(): void {
        this.fetchTopicList(() => {
            this.currentIndex = 0;
            this.checkedOptions = [];
            this.statResult = [];
        });
    }

    public created(): void {
        this.fetchTopicList();
    }
}
