











import { Component, Vue } from 'vue-property-decorator';
import BackgroundMusic from '@/components/BackgroundMusic.vue';
import WeChat from '@/components/WeChat.vue';

@Component({
    components: {
        'background-music': BackgroundMusic,
        'we-chat': WeChat
    }
})
export default class App extends Vue {
    //监听微信配置完成
    public onWeChatReady(): void {
        (this.$refs.bg_music as any).play();
    }
}
