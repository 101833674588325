









































import { Component, Mixins } from 'vue-property-decorator';
import { Popup, Image, List } from 'vant';
import Dialog from '@/mixins/Dialog';

@Component({
    components: {
        'van-popup': Popup,
        'van-image': Image,
        'van-list': List
    }
})
export default class RankList extends Mixins(Dialog) {
    
    public loading: boolean = false;
    public finished: boolean = false;
    public list: any[] = [];
    public ranking: number = 0;
    public page: number = 1;

    //是否显示排行榜
    public get isShowRank(): boolean {
        return this.$game_data.game_status == 3;
    }

    //获取数据列表
    private fetchList(page: number): void {
        this.loading = true;
        this.axios.get(this.$api.GAME_RANKING, { params: { page } }).then((res: any) => {
            if (res.code == 1) {
                const list = res.data.list;
                if (this.isShowRank) {
                    this.ranking = res.data.my_order.ranking;
                }
                if (list == 1 || list.length <= 0) {
                    this.finished = true;
                } else {
                    this.list = this.list.concat(list);
                    this.page = page;
                }
            }
            this.$nextTick(() => {
                this.loading = false;
            });
        });
    }

    //下拉加载更多
    public onLoad(): void {
        this.fetchList(this.page + 1);
    }

    public mounted(): void {
        this.fetchList(this.page);
    }
}
