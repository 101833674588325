/*
    数据接口
*/
import Vue from 'vue';

export namespace API {
    //分享
    export const SHARE = '/game/index/share?url=';
    //分享计数
    export const ADD_SHARE_NUM = '/game/index/add_share_num';

    //引导
    export const GUIDANCE = '/game/index/guidance';
    //登录
    export const LOGIN = '/game/login/profile';
    //认证回调
    export const OAUTH_CALLBACK = '/game/login/oauth_callback?code=';

    //提交联系方式
    export const ADD_GAME = '/game/index/addGame';
    //排行榜
    export const GAME_RANKING = '/game/index/game_ranking';
    //题目列表
    export const TOPIC_LIST = '../json/question.json';
    //export const TOPIC_LIST = 'http://localhost:8080/json/question.json';
}

interface API {
    SHARE: string;
    ADD_SHARE_NUM: string;

    GUIDANCE: string;
    LOGIN: string;
    OAUTH_CALLBACK: string;

    BANK_LIST: string;
    ADD_BANK_NAME: string;
    ADD_GAME: string;
    GAME_RANKING: string;
    TOPIC_LIST: string;
}

declare module "vue/types/vue" {
    interface Vue {
        $api: API
    }
}

Vue.prototype.$api = API;