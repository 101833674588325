/*
    网络请求
*/
import axios from 'axios';
import { Notify } from 'vant';
import { secret, token } from '@/utils/index';
import Secret from '@/utils/Secret';

axios.defaults.baseURL = 'https://gdgame.chuangxiangtiaodong.com/api';

const tokeyStr = token.getToken();
if (tokeyStr) {
    axios.defaults.headers.common['token'] = tokeyStr;
}

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    if (config.data && config.data.enableEncrypt) {
        config.data = secret.encrypt(config.data);
        delete config.data.enableEncrypt;
    }
    return config;
}, function (error) {
    Notify({ type: 'danger', message: "抱歉，网络连接失败" });
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (res: any) {
    const resData = res.data;
    if (resData.data && Secret.isEncryptResult(resData.data)) {
        resData.data = secret.decrypt(resData.data) || {};
    }
    return resData;
}, function (error) {
    Notify({ type: 'danger', message: "抱歉，网络错误" });
    return Promise.reject(error);
});

export default axios;