


















import { Vue, Component } from 'vue-property-decorator';
import { Popup, Image } from 'vant';

@Component({
    components: {
        'van-popup': Popup,
        'van-image': Image
    }
})
export default class SeekResult extends Vue {
    public show: boolean = false;
}
