/*
    背景颜色
*/
import { Component, Vue, Model } from 'vue-property-decorator';

@Component
export default class Dialog extends Vue {
    //显示
    @Model('show', Boolean)
    public show!: boolean;

    //关闭
    public close(): void {
        this.$emit('show', false);
    }
}