


























import { Component, Mixins, Vue } from 'vue-property-decorator';
import { Popup, Toast } from 'vant';
import Dialog from '@/mixins/Dialog';

@Component({
    components: {
        'van-popup': Popup
    }
})
export default class Award extends Mixins(Dialog) {

    //手机号码
    public mobile: string = '';

    //游戏状态
    public status: number = 0;

    //是否禁用提交
    public get isDisableSubmit(): boolean {
        return this.status != 0;
    }

    //是否显示排行榜
    public get isShowRank(): boolean {
        return this.status == 3;
    }

    //提交
    public submit(): void {
        if (this.$game_data.game_status == 3) {
            Toast.fail('您已通关了游戏');
            return undefined;
        } else if (this.$game_data.game_status != 0) {
            return undefined;
        }

        if (this.mobile == '') {
            Toast.fail('请输入正确的手机号');
            return undefined;
        }
        Toast.loading({
            message: '提交中...',
            forbidClick: true,
            duration:0
        });

        this.axios.post(this.$api.ADD_GAME, {
            mobile: this.mobile,
            enableEncrypt: true
        }).then((res: any) => {
            if (res.code == 1) {
                Vue.prototype.$game_data.game_status = 3;
                Vue.prototype.$game_data.my = {
                    ranking: res.data.ranking
                };
                this.status = this.$game_data.game_status;
                Toast.success("提交成功");
            } else {
                Toast.fail(res.message);
            }
        });
    }
    public created(): void {
        this.status = this.$game_data.game_status;
        if (this.isShowRank) {
            this.mobile = this.$game_data.my.mobile;
        }
    }
}
