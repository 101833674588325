
























import { Component, Mixins, Prop} from 'vue-property-decorator';
import { Popup } from 'vant';
import Dialog from '@/mixins/Dialog';

@Component({
    components: {
        'van-popup': Popup
    }
})
export default class Fail extends Mixins(Dialog) {
    //结果
    @Prop(Array)
    public result!: boolean[];

    //再来一次
    public again(): void {
        this.$emit('again');
        this.close();
    }
}
