/*
    令牌
*/
import { Axios } from 'axios';
import axios from 'axios';

export default class Token {
    //设置令牌
    public setToken(token: string, axios?: Axios): void {
        window.localStorage.setItem('token', token);
        if (axios) {
            axios.defaults.headers.common['token'] = token;
        }
    }
    //获取令牌
    public getToken(): string | null {
        return window.localStorage.getItem('token');
    }

    //是否拥有令牌
    public isToken(): boolean {
        return axios.defaults.headers.common['token'] ? true : false;
    }
}