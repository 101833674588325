












import { Component, Mixins } from 'vue-property-decorator';
import { Popup } from 'vant';
import Dialog from '@/mixins/Dialog';

@Component({
    components: {
        'van-popup': Popup
    }
})
export default class Share extends Mixins(Dialog) {
}
